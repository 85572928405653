<!--INVENTARIO DE CLIENTES Y EJECUTIVO DE VENTAS -->
<template>
  <v-card class="mt-8">
    <v-card-title primary-title>
      <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn small icon absolute top right v-bind="attrs" v-on="on">
            <v-icon>{{ icons.mdiMenu }}</v-icon>
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="exportInventario">
            <v-list-item-icon>
              <v-icon>{{ icons.mdiDatabaseExport }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Exportar Inventario</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-title>
    <v-card-title class="primary--text parametrosBusqueda">
      <filters
        :cedis-items="filters.cedisItems"
        :cedis.sync="tableData.cedisIds"
        :search.sync="tableData.nameOrCode"
        @wasClicked="searchByForm()"
      ></filters>
    </v-card-title>
    <v-card-text>
      <v-data-table
        v-show="!tableData.loading"
        :headers="headers"
        :items="products"
        class="elevation-2"
        calculate-widths
        hide-default-footer
        disable-pagination
      >
        <template v-slot:[`item.assigned_code`]="{ item }">
          <a @click="desglozarCodigo(item.assigned_code)" class="text-decoration-underline">{{ item.assigned_code }}</a>
        </template>

        <template v-slot:[`item.thumbnail`]="{ item }">
          <template v-if="item.thumbnail">
            <v-img
              :src="storagePrivateLinkApi(item.thumbnail)"
              aspect-ratio="1"
              max-height="100px"
              width="100px"
              contain
            ></v-img>
          </template>
          <template v-else>
            <v-img :src="getDefaultImage" aspect-ratio="1" max-height="100px" width="100px" contain></v-img>
          </template>
        </template>

        <template v-slot:[`item.description`]="{ item }">
          <v-tooltip bottom max-width="200px">
            <template v-slot:[`activator`]="{ on, attrs }">
              <span v-on="on" v-bind="attrs">{{ shortDescription(item.description) }}</span>
            </template>
            <span>
              {{ item.description }}
            </span>
          </v-tooltip>
        </template>

        <template v-slot:footer>
          <div class="pt-5 px-3 d-flex">
            <div style="width: 100px">
              <v-select
                v-model="tableData.perPage"
                :items="[5, 10, 20, 30, 40, 50]"
                dense
                hide-details
                class="mt-2"
                outlined
              ></v-select>
            </div>
            <v-spacer></v-spacer>
            <p class="mt-2 mr-3">
              <v-btn icon v-if="tableData.page > 1" @click="tableData.page--">
                <v-icon>{{ icons.mdiChevronLeft }}</v-icon>
              </v-btn>
              {{ tableData.page }}
              <v-btn icon @click="tableData.page++" :disabled="!(products.length > 0)">
                <v-icon>{{ icons.mdiChevronRight }}</v-icon>
              </v-btn>
            </p>
          </div>
        </template>
      </v-data-table>
      <tabla-skeleton v-show="tableData.loading" :headers="headers"></tabla-skeleton>
    </v-card-text>
    <desglozar-inventario ref="desglozarInventario" :cedis-items="filters.cedisItems"></desglozar-inventario>
    <export-global-inventory ref="exportGlobalInventary"
    :name-or-code="tableData.nameOrCode"
    :warehouse-id="tableData.cedisIds"
    />
  </v-card>
</template>

<script>
import { mdiImageArea, mdiChevronLeft, mdiChevronRight, mdiMenu, mdiDatabaseExport } from '@mdi/js'

import TablaSkeleton from './table-skeleton.vue'
import filters from './filters.vue'
import helpers from '../../mixins/helpers'
import DesglozarInventario from './DesglozarInvetario.vue'
import ExportGlobalInventory from './ExportGlobalInvetary.vue'
import _ from 'lodash'
import status from './../../data/status'

export default {
  props: {
    headers: {
      type: Array,
    },
  },
  components: {
    filters: filters,
    'tabla-skeleton': TablaSkeleton,
    DesglozarInventario,
    ExportGlobalInventory
  },
  mixins: [helpers],
  data() {
    return {
      filters: {
        cedisItems: [],
      },
      icons: {
        mdiImageArea,
        mdiChevronLeft,
        mdiChevronRight,
        mdiMenu,
        mdiDatabaseExport,
      },
      status: status,
      products: [],
      tableData: {
        nameOrCode: '',
        cedisIds: [],
        page: 1,
        perPage: 10,
        loading: false,
      },
    }
  },
  mounted() {
    this.getInitInfo()
      .then(() => {
        this.searchProducts()
      })
      .catch(res => {
        this.catchResponse(res)
      })
  },
  methods: {
    searchProducts() {
      this.tableData.loading = true
      this.products = []
      let url = this.$base_api_url + `/getProductsForInventoryClients`

      let data = {
        nameOrCode: this.tableData.nameOrCode,
        cedisIds: this.tableData.cedisIds,
        perPage: this.tableData.perPage,
        page: this.tableData.page,
      }
      this.$http
        .post(url, data)
        .then(res => {
          this.products = this.$_.map(res.data.data, item => {
            return {
              ...item,
              status: this.status.getStatusIndex(item.days_of_life),
            }
          })
        })
        .catch(res => {
          this.catchResponse(res)
        })
        .then(() => {
          this.tableData.loading = false
        })
    },
    getInitInfo() {
      return new Promise((resolve, reject) => {
        let url = `${this.$base_api_url}/getInfoFromInventory`

        this.$http
          .post(url)
          .then(res => {
            this.filters.cedisItems = res.data.cedis
            resolve(true)
          })
          .catch(res => {
            reject(res)
          })
      })
    },
    shortDescription(text) {
      if (text.length > 20) {
        return text.slice(0, 20) + '...'
      }
      return text
    },
    searchByForm() {
      //Al momento de actualizar la pagina, se actualiza el contenido, si la pagina es la primera entonces ejecutamos la busqueda
      if (this.tableData.page == 1) {
        this.searchProducts()
      }
      this.tableData.page = 1
    },
    debounceSearchProducts: _.debounce(function () {
      this.searchProducts()
    }, 500),
    desglozarCodigo(assignedCode){
      this.$refs.desglozarInventario.showDesgloce(assignedCode);
    },
    exportInventario(){
      this.$refs.exportGlobalInventary.startExport()
    }
  },
  watch: {
    'tableData.perPage': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.searchProducts()
      }
    },
    'tableData.page': function (newValue, oldValue) {
      if (newValue != oldValue) {
        this.debounceSearchProducts()
      }
    },
  },
}
</script>

<style></style>
