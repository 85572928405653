<template>
  <v-dialog v-model="dialog" max-width="800px" transition="dialog-transition">
    <v-card>
      <v-card-title primary-title> Producto {{ assignedCode }} </v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" md="10">
            <v-select hide-details v-model="selectedCedis" :items="formatCedis" label="Selecciona CEDIS" multiple>
              <template v-slot:prepend-item>
                <v-list-item ripple @click="toggle">
                  <v-list-item-action>
                    <v-icon :color="selectedCedis.length > 0 ? 'indigo darken-4' : ''">
                      {{ icon }}
                    </v-icon>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title> Seleccionar todo </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider class="mt-2"></v-divider>
              </template>
              <template v-slot:selection="{ item, index }">
                <template v-if="selectedCedis.length == formatCedis.length">
                  <span v-if="index == 0">TODOS</span>
                </template>
                <template v-else>
                  <span v-if="index === 0">{{ item.text }}</span>
                  <span v-if="index === 1" class="grey--text text-caption">
                    (+{{ selectedCedis.length - 1 }} otros)
                  </span>
                </template>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-btn block small class="mt-0 mt-sm-4" color="accent" elevation="3" @click="$emit('wasClicked')">
              <v-icon>
                {{ icons.mdiClipboardSearch }}
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-data-table class="mt-2" :loading="loader" :headers="headers" :items="products">
          <template v-slot:[`item.actions`]="{ item }">
            <v-dialog max-width="500px" transition="dialog-transition">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-on="on" v-bind="attrs" icon small>
                  <v-icon>
                    {{ icons.mdiImageArea }}
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:default="d">
                <v-card max-width="100%">
                  <v-card-text>
                    <v-row v-if="item.images.length > 0">
                      <v-col v-for="(img, key) in item.images" :key="key" cols="4">
                        <v-img
                          :src="storagePrivateLinkApi(img)"
                          contain
                          :aspect-ratio="1"
                          max-width="100%"
                          max-height="100%"
                        ></v-img>
                      </v-col>
                    </v-row>
                    <p class="text-subtitle-1" v-else>Este producto no posee imagenes</p>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn @click="d.value = false" color="primary"> ok </v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </template>
          <template #[`item.status`]="{ item }">
            <v-chip v-if="item.status != null" small :color="status.data[item.status].color" class="font-weight-medium">
              {{ status.data[item.status].text }}
            </v-chip>

            <v-chip v-else small :color="status.data[3].color" class="font-weight-medium">
              {{ status.data[3].text }}
            </v-chip>
          </template>
          <template v-slot:[`item.days_of_life`]="{ item }">
            <span v-if="item.expiration === null">-</span>
            <template v-else>
              <v-chip small :color="status.data[item.status].color">{{ item.days_of_life }}</v-chip>
            </template>
          </template>

          <template v-slot:[`item.expiration`]="{ item }">
            <span v-if="item.expiration">{{ item.expiration }}</span>
            <span v-else>-</span>
          </template>
        </v-data-table>
        <div class="my-5">
          <p class="text-caption text-decoration-underline">
            IMPORTANTE: La existencia mostrada aquí no contempla las ordenes pendientes de surtir
          </p>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="error" @click="dialog = false"> Cerrar </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClipboardSearch, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline, mdiImageArea } from '@mdi/js'
import status from './../../data/status'
import helpers from '../../mixins/helpers'

export default {
  name: 'DesglozarInvetario',
  props: {
    cedisItems: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [helpers],
  data: () => ({
    dialog: false,
    assignedCode: null,
    loader: false,
    selectedCedis: [],
    products: [],
    status,
    icons: {
      mdiCloseBox,
      mdiMinusBox,
      mdiCheckboxBlankOutline,
      mdiClipboardSearch,
      mdiImageArea
    },
    headers: [
      {
        text: 'CÓDIGO',
        align: 'center',
        value: 'assigned_code',
      },
      {
        text: 'NOMBRE',
        value: 'name',
      },
      {
        text: 'IMÁGENES',
        value: 'actions',
      },
      {
        text: 'DESCRIPCIÓN',
        value: 'description',
      },
      {
        text: 'CEDIS',
        value: 'cedis_name',
      },
      {
        text: 'LINEA',
        value: 'line',
      },
      {
        text: 'FAMILIA',
        value: 'family',
      },
      {
        text: 'VIGENCIA',
        value: 'expiration',
      },
      {
        text: 'DÍAS DE VIDA',
        value: 'days_of_life',
      },
      {
        text: 'ESTATUS',
        value: 'status',
      },
      {
        text: 'EXISTENCIA',
        value: 'number_of_boxes',
      },
      { text: 'Tipo de almacenaje',
        value: 'tipo_de_almacenaje'
      },
      { text: 'Vigencia tipo de almacenaje',
        value: 'vigencia_tipo_de_almacenaje'
      },
      { text: 'Unidad de negocio',
        value: 'unidad_de_negocio'
      },

    ],
  }),
  methods: {
    showDesgloce(assignedCode) {
      this.assignedCode = assignedCode;
      this.loader = true
      this.dialog = true
      let url = this.$base_api_url + `/getProductsForBreakdown`

      let data = {
        assigned_code: assignedCode,
        cedis_ids: this.selectedCedis,
      }
      this.$http
        .post(url, data)
        .then(res => {
          this.products = this.$_.map(res.data.data, item => {
            return {
              ...item,
              status: this.status.getStatusIndex(item.days_of_life),
            }
          })
        })
        .catch(res => {
          this.catchResponse(res)
        })
        .then(() => {
          this.loader = false
        })
    },
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllCedis) {
          this.selectedCedis = []
        } else {
          this.selectedCedis = this.$_.map(this.cedisItems, item => item.id)
        }
      })
    },
  },
  computed: {
    likesAllCedis() {
      return this.selectedCedis.length === this.formatCedis.length
    },
    likesSomeCedis() {
      return this.selectedCedis.length > 0 && !this.likesAllCedis
    },
    icon() {
      if (this.likesAllCedis) return mdiCloseBox
      if (this.likesSomeCedis) return mdiMinusBox

      return mdiCheckboxBlankOutline
    },
    formatCedis() {
      return this.$_.map(this.cedisItems, item => {
        return {
          text: `${item.name} ${item.code} (${item.state})`,
          value: item.id,
          name: item.name,
        }
      })
    },
  },
}
</script>

<style></style>
