<template>
  <v-card>
    <v-row>
      <v-col cols="12" md="3" v-if="userImage">
        <v-img contain max-height="200px" class="ma-2 ml-3" :src="storagePrivateLinkApi(userImage)"></v-img>
      </v-col>
      <v-col cols="12" md="9">
        <v-card-title class="align-start">
          <p class="primary--text tituloCatalogo">Inventario</p>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12" md="12">
              <p>Elige los parámetros que deseas consultar</p>
              <v-select
                solo
                multiple
                v-model="selectedParameters"
                return-object
                :items="headersInTable"
                label="Parámetros"
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectedParameters.length > 0 ? 'indigo darken-4' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Seleccionar todo
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiAccountOutline,
  mdiCurrencyUsd,
  mdiTrendingUp,
  mdiDotsVertical,
  mdiLabelOutline,
  mdiFormSelect,
  mdiCloseBox,
  mdiMinusBox,
  mdiCheckboxBlankOutline,
} from '@mdi/js'

import _ from 'lodash'
import helpers from '../../mixins/helpers'

export default {
  mixins: [helpers],
  props: {
    allHeaders: {
      type: Array,
      default: () => [],
    },
    currentHeaders: {
      type: Array,
      default: () => [],
    },
  },
  model: {
    prop: 'currentHeaders',
    event: 'update:headers',
  },
  mounted() {
    this.selectedParameters = _.map(this.currentHeaders, item => ({
      text: item.text,
      value: item.value,
      data: item,
    }))
  },
  data() {
    return {
      // icons
      icons: {
        mdiDotsVertical,
        mdiTrendingUp,
        mdiAccountOutline,
        mdiLabelOutline,
        mdiCurrencyUsd,
        mdiFormSelect,
      },
      selectedParameters: [],
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllParameters) {
          this.selectedParameters = []
        } else {
          this.selectedParameters = this.headersInTable.slice()
        }
      })
    },
  },
  computed: {
    likesAllParameters() {
      return this.selectedParameters.length === this.headersInTable.length
    },
    likesSomeParameter() {
      return this.selectedParameters.length > 0 && !this.likesAllParameters
    },
    icon() {
      if (this.likesAllParameters) return mdiCloseBox
      if (this.likesSomeParameter) return mdiMinusBox

    return mdiCheckboxBlankOutline
    },
    headersInTable() {
      return _.map(this.allHeaders, item => ({
        text: item.text,
        value: item.value,
        data: item,
      }))
    },
    userImage(){
      return this.$store.getters.getUser.img_company;
    }
  },
  watch: {
    selectedParameters(now) {

      //Ordenamos las cabeceras
      let headersOrder = this.allHeaders;


      let currentItems = _.filter(headersOrder,item=>{
        return _.find(now,{data: {value: item.value}})
      })

      this.$emit(
        'update:headers',
        currentItems,
      )
    },
    /* currentHeaders(now) {
      this.selectedParameters = _.map(now, item => ({
        text: item.text,
        value: item.value,
        data: item,
      }))
    }, */
  },
}
</script>
<style>
.tituloCatalogo {
  font-size: 1.5em !important;
}
</style>
