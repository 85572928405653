var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"800px","transition":"dialog-transition"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Producto "+_vm._s(_vm.assignedCode)+" ")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('v-select',{attrs:{"hide-details":"","items":_vm.formatCedis,"label":"Selecciona CEDIS","multiple":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":_vm.toggle}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectedCedis.length > 0 ? 'indigo darken-4' : ''}},[_vm._v(" "+_vm._s(_vm.icon)+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Seleccionar todo ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(_vm.selectedCedis.length == _vm.formatCedis.length)?[(index == 0)?_c('span',[_vm._v("TODOS")]):_vm._e()]:[(index === 0)?_c('span',[_vm._v(_vm._s(item.text))]):_vm._e(),(index === 1)?_c('span',{staticClass:"grey--text text-caption"},[_vm._v(" (+"+_vm._s(_vm.selectedCedis.length - 1)+" otros) ")]):_vm._e()]]}}]),model:{value:(_vm.selectedCedis),callback:function ($$v) {_vm.selectedCedis=$$v},expression:"selectedCedis"}})],1),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mt-0 mt-sm-4",attrs:{"block":"","small":"","color":"accent","elevation":"3"},on:{"click":function($event){return _vm.$emit('wasClicked')}}},[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiClipboardSearch)+" ")])],1)],1)],1),_c('v-data-table',{staticClass:"mt-2",attrs:{"loading":_vm.loader,"headers":_vm.headers,"items":_vm.products},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-dialog',{attrs:{"max-width":"500px","transition":"dialog-transition"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","icon":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" "+_vm._s(_vm.icons.mdiImageArea)+" ")])],1)]}},{key:"default",fn:function(d){return [_c('v-card',{attrs:{"max-width":"100%"}},[_c('v-card-text',[(item.images.length > 0)?_c('v-row',_vm._l((item.images),function(img,key){return _c('v-col',{key:key,attrs:{"cols":"4"}},[_c('v-img',{attrs:{"src":_vm.storagePrivateLinkApi(img),"contain":"","aspect-ratio":1,"max-width":"100%","max-height":"100%"}})],1)}),1):_c('p',{staticClass:"text-subtitle-1"},[_vm._v("Este producto no posee imagenes")])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){d.value = false}}},[_vm._v(" ok ")])],1)],1)]}}],null,true)})]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status != null)?_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.status.data[item.status].color}},[_vm._v(" "+_vm._s(_vm.status.data[item.status].text)+" ")]):_c('v-chip',{staticClass:"font-weight-medium",attrs:{"small":"","color":_vm.status.data[3].color}},[_vm._v(" "+_vm._s(_vm.status.data[3].text)+" ")])]}},{key:"item.days_of_life",fn:function(ref){
var item = ref.item;
return [(item.expiration === null)?_c('span',[_vm._v("-")]):[_c('v-chip',{attrs:{"small":"","color":_vm.status.data[item.status].color}},[_vm._v(_vm._s(item.days_of_life))])]]}},{key:"item.expiration",fn:function(ref){
var item = ref.item;
return [(item.expiration)?_c('span',[_vm._v(_vm._s(item.expiration))]):_c('span',[_vm._v("-")])]}}],null,true)}),_c('div',{staticClass:"my-5"},[_c('p',{staticClass:"text-caption text-decoration-underline"},[_vm._v(" IMPORTANTE: La existencia mostrada aquí no contempla las ordenes pendientes de surtir ")])])],1),_c('v-card-actions',[_c('v-btn',{attrs:{"text":"","color":"error"},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Cerrar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }