<template>
  <div>
    <v-dialog v-model="dialog" max-width="500px" transition="dialog-transition">
      <v-card>
        <v-card-text class="pt-3">
          <h4 class="text-center text-h5 mt-5">
            Se exporto correctamente el inventario global
          </h4>
          <v-row dense justify="center" class="mt-5">
            <v-col cols="auto">
              <v-btn color="info" :href="link" download>
                Descargar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn text small block @click="dialog = false">
            salir
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate color="primary" class="mx-auto d-block" size="40"></v-progress-circular>
      <p>Exportando inventario...</p>
    </v-overlay>
  </div>
</template>

<script>
import helpers from '../../mixins/helpers';


export default {
  name: "ExportGlobalInventory",
  mixins: [helpers],
  props: {
    nameOrCode: {
      type: String,
      default: null
    },
    warehouseId: {
      type: Array,
      default: null
    }
  },
  data: () => ({
    dialog: false,
    link: null,
    loader: false
  }),
  methods: {
    startExport() {
      this.loader = true
      let url = this.$base_api_url + `/invetory/exportGlobal`;

      let data = {
        name_or_code: this.nameOrCode,
        warehouse_id: this.warehouseId
      };

      this.$http
        .post(url, data)
        .then(res => {
          this.dialog = true;
          this.link = this.storagePrivateLinkApi(res.data.path);
        })
        .catch(res => {
          this.catchResponse(res);
        })
        .then(() => {
          this.loader = false
        });
    }
  }
};
</script>

<style></style>
