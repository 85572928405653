<template>
  <v-container fluid>
    <titulo v-model="currentHeaders" :all-headers="allHeader"></titulo>
    <tablaInventario :headers="currentHeaders"></tablaInventario>
  </v-container>
</template>

<script>
import tablaInventario from './tabla-inventario.vue'
import titulo from './Titulo.vue'
const currentVersion = '2.4';
export default {
  name: 'Inventario',
  components: {
    tablaInventario,
    titulo,
  },
  data() {
    let defaultHeaders = [
        { text: "Imagen", value: "thumbnail" },
        {
          text: "Código",
          value: "assigned_code",
          width: "150px"
        },
        /* { text: "Cliente", value: "client" }, */
        { text: "Nombre", value: "name" },
        { text: "Disponible", value: "available" },
        { text: "Reservados", value: "reserved" },
        { text: "Total", value: "total" },
        { text: "Cuarentena", value: "quarantine" },
        { text: "Descripción", value: "description" },
        { text: "Categoría", value: "category" },
        { text: "Linea", value: "line" },
        { text: "Familia", value: "family" },
        { text: "Nombre de la actividad", value: "name_of_activity" }
    ];


    let storageHeader = this.$storage.get('inventory-current-headers', { json: true })

    if (storageHeader.version == currentVersion && Array.isArray(storageHeader.headers)) {
      defaultHeaders = storageHeader.headers;
    }

    return {
      allHeader: [
        { text: "Imagen", value: "thumbnail" },
        {
          text: "Código",
          value: "assigned_code",
          width: "150px"
        },
        { text: "Cliente", value: "client" },
        { text: "Nombre", value: "name" },
        { text: "Disponible", value: "available" },
        { text: "Reservados", value: "reserved" },
        { text: "Total", value: "total" },
        { text: "Cuarentena", value: "quarantine" },
        { text: "Descripción", value: "description" },
        { text: "Categoría", value: "category" },
        { text: "Linea", value: "line" },
        { text: "Familia", value: "family" },
        { text: "Nombre de la actividad", value: "name_of_activity" }
      ],
      currentHeaders: defaultHeaders,
    }
  },
  watch: {
    currentHeaders(now) {
      const data = {
        version: currentVersion,
        headers: now
      }
      this.$storage.set('inventory-current-headers', data, { json: true })
    },
  },
}
</script>

<style lang="css"></style>
