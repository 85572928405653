var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mt-8"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":"","absolute":"","top":"","right":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiMenu))])],1)]}}])},[_c('v-list',[_c('v-list-item',{on:{"click":_vm.exportInventario}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDatabaseExport))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Exportar Inventario")])],1)],1)],1)],1)],1),_c('v-card-title',{staticClass:"primary--text parametrosBusqueda"},[_c('filters',{attrs:{"cedis-items":_vm.filters.cedisItems,"cedis":_vm.tableData.cedisIds,"search":_vm.tableData.nameOrCode},on:{"update:cedis":function($event){return _vm.$set(_vm.tableData, "cedisIds", $event)},"update:search":function($event){return _vm.$set(_vm.tableData, "nameOrCode", $event)},"wasClicked":function($event){return _vm.searchByForm()}}})],1),_c('v-card-text',[_c('v-data-table',{directives:[{name:"show",rawName:"v-show",value:(!_vm.tableData.loading),expression:"!tableData.loading"}],staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.products,"calculate-widths":"","hide-default-footer":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item.assigned_code",fn:function(ref){
var item = ref.item;
return [_c('a',{staticClass:"text-decoration-underline",on:{"click":function($event){return _vm.desglozarCodigo(item.assigned_code)}}},[_vm._v(_vm._s(item.assigned_code))])]}},{key:"item.thumbnail",fn:function(ref){
var item = ref.item;
return [(item.thumbnail)?[_c('v-img',{attrs:{"src":_vm.storagePrivateLinkApi(item.thumbnail),"aspect-ratio":"1","max-height":"100px","width":"100px","contain":""}})]:[_c('v-img',{attrs:{"src":_vm.getDefaultImage,"aspect-ratio":"1","max-height":"100px","width":"100px","contain":""}})]]}},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":"","max-width":"200px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(_vm.shortDescription(item.description)))])]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(item.description)+" ")])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"pt-5 px-3 d-flex"},[_c('div',{staticStyle:{"width":"100px"}},[_c('v-select',{staticClass:"mt-2",attrs:{"items":[5, 10, 20, 30, 40, 50],"dense":"","hide-details":"","outlined":""},model:{value:(_vm.tableData.perPage),callback:function ($$v) {_vm.$set(_vm.tableData, "perPage", $$v)},expression:"tableData.perPage"}})],1),_c('v-spacer'),_c('p',{staticClass:"mt-2 mr-3"},[(_vm.tableData.page > 1)?_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.tableData.page--}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronLeft))])],1):_vm._e(),_vm._v(" "+_vm._s(_vm.tableData.page)+" "),_c('v-btn',{attrs:{"icon":"","disabled":!(_vm.products.length > 0)},on:{"click":function($event){_vm.tableData.page++}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiChevronRight))])],1)],1)],1)]},proxy:true}],null,true)}),_c('tabla-skeleton',{directives:[{name:"show",rawName:"v-show",value:(_vm.tableData.loading),expression:"tableData.loading"}],attrs:{"headers":_vm.headers}})],1),_c('desglozar-inventario',{ref:"desglozarInventario",attrs:{"cedis-items":_vm.filters.cedisItems}}),_c('export-global-inventory',{ref:"exportGlobalInventary",attrs:{"name-or-code":_vm.tableData.nameOrCode,"warehouse-id":_vm.tableData.cedisIds}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }