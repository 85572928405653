<template>
  <v-row dense>
    <v-col cols="12" md="3">
      <v-text-field v-model="currentSeach" label="Busqueda por código o nombre" single-line hide-details v-on:keyup.enter="$emit('wasClicked')"></v-text-field>
    </v-col>
    <v-col cols="12" md="7">
      <v-select hide-details v-model="selectedCedis" :items="formatCedis" label="Selecciona CEDIS" multiple>
        <template v-slot:prepend-item>
          <v-list-item ripple @click="toggle">
            <v-list-item-action>
              <v-icon :color="selectedCedis.length > 0 ? 'indigo darken-4' : ''">
                {{ icon }}
              </v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                Seleccionar todo
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
          <template v-if="selectedCedis.length == formatCedis.length">
            <span v-if="index == 0">TODOS</span>
          </template>
          <template v-else>
            <span v-if="index === 0">{{ item.text }}</span>
            <span v-if="index === 1" class="grey--text text-caption"> (+{{ selectedCedis.length - 1 }} otros) </span>
          </template>
        </template>
      </v-select>
    </v-col>
    <v-col cols="12" md="2">
      <v-btn block small class="mt-0 mt-sm-4" color="accent" elevation="3" @click="$emit('wasClicked')">
        <v-icon>
          {{ icons.mdiClipboardSearch }}
        </v-icon>
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import { mdiImageArea, mdiClipboardSearch, mdiCloseBox, mdiMinusBox, mdiCheckboxBlankOutline } from '@mdi/js'

export default {
  name: 'filters',
  props: {
    cedisItems: {
      type: Array,
      default: () => [],
    },
    cedis:{
      type: Array,
      default: () => [],
    },
    search: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      selectedCedis: [],
      currentSeach: '',
      icons: {
        mdiImageArea,
        mdiClipboardSearch,
        mdiCloseBox,
        mdiMinusBox,
        mdiCheckboxBlankOutline,
      },
    }
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.likesAllCedis) {
          this.selectedCedis = []
        } else {
          this.selectedCedis = this.$_.map(this.cedisItems, item => item.id)
        }
      })
    },
  },
  computed: {
    likesAllCedis() {
      return this.selectedCedis.length === this.formatCedis.length
    },
    likesSomeCedis() {
      return this.selectedCedis.length > 0 && !this.likesAllCedis
    },
    icon() {
      if (this.likesAllCedis) return mdiCloseBox
      if (this.likesSomeCedis) return mdiMinusBox

      return mdiCheckboxBlankOutline
    },
    formatCedis() {
      return this.$_.map(this.cedisItems, item => {
        return {
          text: `${item.name} ${item.code} (${item.state})`,
          value: item.id,
          name: item.name,
        }
      })
    },
  },
  watch: {
    selectedCedis(now) {
      this.$storage.set('inventory-current-storage', now, { json: true })
      this.$emit('update:cedis',now)
    },
    currentSeach(now){
      this.$emit('update:search',now)
    },
    cedisItems(now){

      //Si se establecen cedis, se obtiene los valores que vienen del local storage
      if (Array.isArray(now) && now.length > 0) {
        let localCedis = this.$storage.get('inventory-current-storage', { json: true, default: [] })

        //Veririfcamos que los cedis existan con lo que vienen del servidor

        this.selectedCedis = this.$_.filter(localCedis, id => {
          return this.$_.find(now,{id: id});
        });

      }
    }
  },
}
</script>
